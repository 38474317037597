import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../style/normalize.css"
import "../style/all.scss"

const Production = () => {
    return (
        <Layout>
            <SEO title="Production" />
            <div style={{ backgroundColor: 'whitesmoke', borderRadius: 20.,}}>
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        margin: "3rem auto",
                        justifyContent: "space-around",

                    }}
                >
                    <div>
                        <iframe src="/production-files/mybib.html" title="_" style={{ width: '90vw', height: '80vh' }}></iframe>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Production